import initialState from './initial_state'
import * as types from '../actions/action_types'

export default function (state = initialState.reducerErrorSinDatosMsj, action) {

    switch (action.type) {
        case types.SIN_DATOS_MSJ:
            return {
                errorSinDatosMsjLoaded: true,
                errorSinDatosMsj: action.msj ? action.msj : [{}],
            }
        case types.LIMPIAR_ERROR_MSJ:
            return {
                errorSinDatosMsjLoaded: false,
                errorSinDatosMsj: action.msj ? action.msj : [{}],
            }
        default:
            return state;
    }

}
