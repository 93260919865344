import initialState from './initial_state'
import * as types from '../actions/action_types'

export default function (state = initialState.reducerTasks, action) {

    // console.log("SSSS:", action)

    switch (action.type) {
        case types.ITEM_LIST_TASKS_FULFILLED:
            return {
                itemListTasks: action.payload ? action.payload.data.results : [{}],
                isitemListTasksLoaded: true
            }

        default:
            return state
    }
}
