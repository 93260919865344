import {
  CHANGE_GRID_STATUS,
  CLEAN_GRID_CLICKS,
  REMOVE_GRID,
  REMOVE_ALL_GRIDS,
  GET_APPLIED_CHANGES
} from '../actions/action_types'

export default function (state = {}, action) {
  //console.log(action.type)
  switch (action.type) {
    case CHANGE_GRID_STATUS:
      let grid = {}
      grid[action.gridName] = {
        gridDimension: action.gridDimension,
        gridDimensionName: action.gridDimensionName,
        gridSelectedRows: action.gridSelectedRows,
        gridWasClicked: action.gridWasClicked,
        gridLevel: action.gridLevel,
        gridAppliedChanges: action.gridAppliedChanges,
      }
      return Object.assign({}, state, grid)

    case GET_APPLIED_CHANGES:
      let gridStatus = { ...state }
      gridStatus[action.gridName].gridAppliedChanges = action.gridAppliedChanges
      return gridStatus

    case CLEAN_GRID_CLICKS:
      let st = { ...state }
      for (let key in st) {
        st[key].gridWasClicked = false
      }
      return st

    case REMOVE_GRID:
      let newState = {}
      Object.keys(state).forEach(key => {
        //console.log('Reducer removing: ', action.gridName.toString())
        if (key.toString() !== action.gridName.toString()) {
          newState[key] = state[key]
        }
      })
      return newState

    case REMOVE_ALL_GRIDS:
      return {}

    default:
      return state
  }
}
