import initialState from './initial_state'
import * as types from '../actions/action_types'

export default function (state = initialState.reducerAtencionMsj, action) {
    switch (action.type) {
        case types.ATENCION_MSJ:
            return {
                atencionMsjLoaded: true,
                atencionMsj: action.msj ? action.msj : [{}],
            }
        default:
            return state;
    }

}
