import axios from 'axios'
import store from './store'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const BACKEND_URL_WO = process.env.REACT_APP_BACKEND_WO

export function getPDF(url) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.get(BACKEND_URL + url, { responseType: 'blob' })
}

export function get(url) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.get(BACKEND_URL + url)
}

export function post(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.post(BACKEND_URL + url, body)
}

export function heavyPost(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.post(BACKEND_URL + url, body, { timeout: 10000000 })
}

//WO
export function WOPost(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.post(BACKEND_URL_WO + url, body)
}

export function WOPut(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.put(BACKEND_URL_WO + url, body)
}

export function WODelete(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`
  return axios.delete(BACKEND_URL_WO + url, body)
}