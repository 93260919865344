import * as api from '../api_service'
import * as types from './action_types'

export function authLogin(token, pathname) {
  const request = api.get(`/login?id=${token}&path=${pathname}`)
  return {
    type: types.AUTH_LOGIN,
    payload: request
  }
}
