import { AUTH_LOGIN_FULFILLED } from '../actions/action_types'
import initialState from './initial_state'

export default function (state = initialState.userProfile, action) {
  switch (action.type) {
    case AUTH_LOGIN_FULFILLED:
      return {
        isAuthenticated: action.payload.data.success,
        manufacturer: action.payload.data.user.account.tab_provider_id,
        downloadingDaysLimit: action.payload.data.user.downloadingDaysLimit,
        token: action.payload.data.token
      }

    default:
      return state
  }
}
