import Alert from 'react-s-alert'
import moment from 'moment'

//FUNCTIONS
export function normalizeValue(value) {
  if (Array.isArray(value)) return value.map(v => v.value)
  else if (value) {
    return value.value
  }

  return value
}

export function normalizeSelect(value) {
  return Array.isArray(value) ? value.map(v => v.value) : value
}

//normalizar los initialValues que son objects
export function normalizeObject(element) {
  if (typeof element === 'object')
    return normalizeValue(element)
  else
    return element
}

export function normalizeElement(element) {
  if (typeof element === 'object' && element !== null) {
    if (Array.isArray(element))
      return element.map(v => v.value).join(',')
    else if (typeof element.value !== 'undefined' && element.value !== null)
      if (Array.isArray(element.value)) {
        return element.value.join(',')
      } else {
        return element.value
      }
    else if (element.from) {
      return {
        from: moment(element.from).format('YYYY-MM-DD'),
        to: moment(element.to).format('YYYY-MM-DD')
      }
    }
  }
  else
    return element
}

export function countGridWithNotAppliedChanges(grids) {
  let gridsWithNotAppliedChanges = 0
  if (grids)
    for (let key in grids)
      if (!grids[key].gridAppliedChanges) gridsWithNotAppliedChanges++
  return gridsWithNotAppliedChanges
}

//FAVOURITES
/**
 * 
 * @param {object} data 
 * @param {any} favValue 
 * @param {any} defaultValue 
 * @param {number} defaultIndex 
 */
export function setFavouriteToInitialValue(data, favValue, defaultValue = null, defaultIndex = -1) {
  if (typeof favValue !== 'undefined' && favValue !== null) {
    if (typeof favValue === 'number' || typeof favValue === 'string') {
      return data.find(r => String(r.value) === String(favValue))
    } else if (typeof favValue === 'object') {
      return favValue.map(r => data.find(s => String(s.value) === String(r))).filter(t => t !== undefined)
      //return data.filter(r => { return favValue.indexOf(typeof r.value === 'number' ? '' + r.value : r.value) >= 0 })
    }
  } else if (favValue === null && defaultValue === null) {
    return data.find(r => r.value === favValue)
  } else {
    return (defaultIndex > -1 && data[defaultIndex]) ? data[defaultIndex] : defaultValue
  }
}

/**
 * Reduce fav values from configuration to props
 * @param {object} data 
 */
export function reduceFavValues(data) {
  const values = JSON.parse(data)
  return values.reduce((r, i) => {
    if (i['type'] === 'string') r[i['name']] = i['value']
    else if (i['type'] === 'boolean') r[i['name']] = i['value']
    else if (i['type'] === 'int') r[i['name']] = parseFloat(i['value'])
    else if (i['type'] === 'array') r[i['name']] = i['value'].split(',')
    else if (i['type'] === 'variable_filters') r[i['name']] = i['value'].split(';')
    return r
  }, {})
}

/**
 * 
 * @param {Error} e       Error message
 * @param {String} source String of source name with error
 */

export function handleFetchingError(e, source = null) {
  if (source) {
    Alert.error(`<h3>Ha ocurrido un error cargando <em>${source}</em> del reporte</h3>`, { position: 'top', efect: 'slide' })
  } else {
    Alert.error(
      '<h3>Ha ocurrido un error cargando los datos para las selecciones de este reporte, favor comunicarse con soporte@datamind.cl si el problema persiste</h3>'
    )
  }
}

export function reformatedata (data) {
  const reformatedata = data.map((info,) => {
    return {
      value: info.key,
      label: info.label
    };
  })
  return reformatedata
}

export function makeOldValueStringInFavs (data) {
  switch (data) {
    case 0:
      return 'bs'
    case 1:
      return 'ns'
    case 2:
      return 'gs'
    case 3:
      return 'nc'
    case 4:
      return 'u'
    case 5:
      return 'v'
  }
  return data
}