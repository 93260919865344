import initialState from './initial_state'
import * as types from '../actions/action_types'


export default function currentPaginationPhotos (state = null, action) {
    
    switch (action.type) {
        case types.CURRENTPAGINATIONPHOTOS:
            return action.payload;
            default:
                return state
    }

}