import initialState from './initial_state'
import * as types from '../actions/action_types'

export default function (state = initialState.reducerPDFGallery, action) {

    // console.log("SSSS:", action)

    switch (action.type) {
        case types.GENERATE_PDF_GALLERY_FULFILLED:
            return {
                PDFGallery: action.payload ? action.payload : [{}],
                isPDFGalleryLoaded: true
            }
        default:
            return state
    }
}
