import React from 'react'
import Loadable from 'react-loadable'
import ReactLoading from 'react-loading'

/**
 * CLASSIC COMPONENTS
 */
export const TaskmanagerCatalog = Loadable({
  loader: () => import( /* webpackChunkName: '_taskmanager_catalog' */'./containers/taskmanager_catalog'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TaskmanagerTasks = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks' */'./containers/taskmanager_tasks'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const MonthlySalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_detail_monthly' */ './containers/sales_detail_monthly'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const BudgetMonthlyProduct = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_detail_monthly' */ './containers/budget_monthly_product'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const CatalogDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_catalog_detail' */ './containers/catalog_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderMonthly = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_monthly' */ './containers/downloader_monthly'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderWeekly = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_weekly' */ './containers/downloader_weekly'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderDaily = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_daily' */ './containers/downloader_daily'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DailySalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_detail_daily' */ './containers/sales_detail_daily'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const WeeklySalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_weekly' */ './containers/sales_detail_weekly'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const InventoryDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'inv_detail' */ './containers/inventory_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const InstockDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'inv_instock' */ './containers/instock_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const InventoryDOH = Loadable({
  loader: () => import(/* webpackChunkName: '_inv_doh' */ './containers/inventory_doh'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const NoSalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'no_sales' */ './containers/no_sales_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})
export const LostSalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_lost_sales' */ './containers/lost_sales_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DailySalesWithStockDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_with_stock' */'./containers/sales_detail_daily_with_stock'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0,
})

export const WeeklySalesWithStockDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_with_stock_week' */'./containers/sales_detail_weekly_with_stock'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0,
})

export const MonthlySalesWithStockDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_with_stock_month' */'./containers/sales_detail_monthly_with_stock'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0,
})

/**
 * FAVS COMPONENTS
 */
export const ReturnsDetail = Loadable({
  loader: () => import(/* webpackChunkName: '_returns' */'./containers/returns_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const PurchaseOrders = Loadable({
  loader: () => import(/* webpackChunkName: '_purchase_orders_daily' */'./containers/purchase_orders_daily'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const InventoryMultiDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'inv_m_detail' */ './containers/inventory_multi_detail'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderManagementWeekly = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_managementweekly' */ './containers/downloader_management_weekly'),
  loading() {
    return <ReactLoading type='spinningBubbles' color='#444' delay={0} />
  }, delay: 0
})

/* DESCARGADORES POST FAVORITOS */
export const OperationalIndicators = Loadable({
  loader: () => import(/* webpackChunkName: '_operational_indicators' */ './containers/downloader_indicators'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderSmartOSA = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_smart_osa' */ './containers/downloader_smart_osa'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TaskmanagerCenterTasks = Loadable({
  loader: () => import(/* webpackChunkName: '_downloader_osa' */ './containers/taskmanager_center_tasks'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DEInstockMonth = Loadable({
  loader: () => import(/* webpackChunkName: '_ch_de_instock_month' */ './containers/de_instock_month'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TaskmanagerImgDisplay = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks_imdisplay' */'./containers/taskmanager_img_display'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const Taskmanager2ImgDisplay = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks2_imdisplay' */'./containers/taskmanager2_img_display'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const Taskmanager2CenterTasksDisplay = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks2_centertaskdisplay' */'./containers/taskmanager2_center_tasks_display'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TaskmanagerCatalogDisplay = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks_catalogdisplay' */'./containers/taskmanager_catalog_display'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TaskmanagerCenterTasksDisplay = Loadable({
  loader: () => import( /* webpackChunkName: '_tasks_centertaskdisplay' */'./containers/taskmanager_center_tasks_display'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const oosXSelloutDownloader = Loadable({
  loader: () => import( /* webpackChunkName: '_oos_x_so' */'./containers/oos_x_sellout_downloader'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const InventoryDaily = Loadable({
  loader: () => import( /* webpackChunkName: '_inv_zero' */'./containers/inventory_daily'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TMSummaryDownloader = Loadable({
  loader: () => import( /* webpackChunkName: '_tm_summary_downloader' */'./containers/taskmanager_summary_downloader'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const TMDaySummaryStoreDownloader = Loadable({
  loader: () => import( /* webpackChunkName: '_tm_summary_downloader' */'./containers/taskmanager_tasks_day_summary_store'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const downloaderMaestra = Loadable({
  loader: () => import( /* webpackChunkName: '_tm_summary_downloader2' */'./containers/downloader_Maestra'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})



export const downloaderLocales = Loadable({
  loader: () => import( /* webpackChunkName: '_tm_summary_downloader' */'./containers/downloader_Locales'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})


export const DownloaderTm2Notes = Loadable({
  loader: () => import( /* webpackChunkName: '_tm2_notes_downloader' */'./containers/downloader_notes_tm2'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
}
)

export const DownloaderTm2CampaignRealizedTaskCenter = Loadable({
  loader: () => import( /* webpackChunkName: '_tm2_campaign_realized_task_center_downloader' */'./containers/downloader_campaign_realized_task_center_tm2'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderTm2Actions = Loadable({
  loader: () => import( /* webpackChunkName: '_tm2_actions_downloader' */'./containers/downloader_actions_tm2'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const DownloaderProductTasksTm2 = Loadable({
  loader: () => import( /* webpackChunkName: '_downloader_product_tasks' */'./containers/downloader_product_tasks'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const PrinselDownloaderDaily2 = Loadable({
  loader: () => import( /* webpackChunkName: '_prinsel_daily_tasks2' */'./containers/prinsel_downloader_daily2'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

// ------------------ SMU ---------------------------
export const SalesSMU = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_detail_daily' */ './containers/sales_smu'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const StockSMU = Loadable({
  loader: () => import(/* webpackChunkName: '_sales_detail_daily' */ './containers/stock_smu'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})

export const CatalogSMU = Loadable({
  loader: () => import(/* webpackChunkName: '_catalog' */ './containers/catalog_smu'),
  loading() { return <ReactLoading type='spinningBubbles' color='#444' delay={0} /> },
  delay: 0
})