import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import QueryFilterReducer from './reducer_query_filter'
import loginReducer from './reducer_auth_login'
import statusReducer from './reducer_status'
import gridReducer from './reducer_grid'
import queriesReducer from './reducer_queries'
import favReducer from './reducer_favourites'
import GalleryReducer from './reducer_gallery'
import GalleryMsjReducer from './reducer_gallery_msj'
import GalleryErrorMsjReducer from './reducer_gallery_error_msj'
import EmailHierarchies from './reducer_email_hierarchies'
import Campaigns from './reducer_campaigns'
import Tasks from './reducer_tasks'
import Questions from './reducer_questions'
import PDFGallery from './reducer_pdf_gallery'
import currentPaginationPhotos from './reducer_current_pagination_photos'

const rootReducer = combineReducers({
  queryFilter: QueryFilterReducer,
  userProfile: loginReducer,
  appStatus: statusReducer,
  favourites: favReducer,
  grids: gridReducer,
  form: formReducer,
  queriesReducer,
  GalleryReducer,
  GalleryMsjReducer,
  GalleryErrorMsjReducer,
  EmailHierarchies,
  Campaigns,
  Tasks,
  Questions,
  PDFGallery,
  currentPaginationPhotos: currentPaginationPhotos
})

export default rootReducer
