import {
  DAILY_SALES_QUERY_FULFILLED,
  DAILY_SALES_QUERY_REJECTED
} from '../actions/action_types'
//import initialState from './initial_state'

export default function (state = [], action) {
  switch (action.type) {
    case DAILY_SALES_QUERY_FULFILLED:
      return {
        data: action.payload.data
      }
    case DAILY_SALES_QUERY_REJECTED:
      return {
        data: action.payload.data
      }
    default:
      return state
  }
}
