import { Map, OrderedMap } from 'immutable'
const emptyFilters = OrderedMap()

export default {
  queryFilter: Map({ filters: emptyFilters, fields: [] }),
  userProfile: { isAuthenticated: false, token: '', user: {} },
  reducerItemListGallery: [{}],
  reducerAtencionMsj: [{}],
  reducerErrorSinDatosMsj: [{}],
  reducerEmailHierarchies: [{}],
  reducerCampaigns: [{}],
  reducerTasks: [{}],
  reducerQuestions: [{}],
  reducerPDFGallery: [{}],
}
