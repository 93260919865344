import initialState from './initial_state'
import * as types from '../actions/action_types'

export default function (state = initialState.reducerItemListGallery, action) {

    switch (action.type) {
        case types.LOAD_GALLERY_FULFILLED:
            if (action.payload) {
                if (action.payload.data) {
                    if (action.payload.data.results.length > 0) {
                        return {
                            itemListGalley: action.payload ? action.payload.data.results : [{}],
                            isItemListGalleyLoaded: true
                        }
                    } else {
                        return {
                            itemListGalley: action.payload ? action.payload.data.results : [{}],
                            isItemListGalleyLoaded: false
                        }
                    }
                }
            }

        default:
            return state;
    }
}
