export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CHANGE_FILTER_FIELD = 'CHANGE_FILTER_FIELD'
export const CHANGE_FILTER_OPERATOR = 'CHANGE_FILTER_OPERATOR'
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE'

export const FETCH_FIELDS = 'FETCH_FIELDS'
export const FETCH_FIELDS_FULFILLED = 'FETCH_FIELDS_FULFILLED'
export const GENERATE_REPORT = 'generate_report'
export const GENERATE_REPORT_FULFILLED = 'generate_report_fulfilled'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED'
export const FETCH_DIMENSION_OPTIONS = 'FETCH_DIMENSION_OPTIONS'
export const CLEAR_DAILY_DATEPICKER = 'CLEAR_DAILY_DATEPICKER'

export const CHANGE_EXPORTING_FORMAT = 'CHANGE_EXPORTING_FORMAT'
export const EXECUTE_VARIATION_QUERY = 'EXECUTE_VARIATION_QUERY'
export const FETCH_INVENTORY_DATES_ALL = 'FETCH_INVENTORY_DATES_ALL'
export const FETCH_INVENTORY_DATES_FULFILLED =
  'FETCH_INVENTORY_DATES_ALL_FULFILLED'
export const FETCH_INVENTORY_DATES_RECENT = 'FETCH_INVENTORY_DATES_RECENT'
export const FETCH_INVENTORY_DATES_RECENT_FULFILLED =
  'FETCH_INVENTORY_DATES_RECENT_FULFILLED'

export const CHANGE_GRID_STATUS = 'CHANGE_GRID_STATUS'
export const CLEAN_GRID_CLICKS = 'CLEAN_GRID_CLICKS'

export const DAILY_SALES_QUERY = 'DAILY_SALES_QUERY'
export const DAILY_SALES_QUERY_FULFILLED = 'DAILY_SALES_QUERY_FULFILLED'
export const DAILY_SALES_QUERY_REJECTED = 'DAILY_SALES_QUERY_REJECTED'

export const MAX_AVAILABLE_DATES = 'MAX_AVAILABLE_DATES'

export const REMOVE_GRID = 'REMOVE_GRID'
export const REMOVE_ALL_GRIDS = 'REMOVE_ALL_GRIDS'

export const FETCH_SALES_DATES = 'FETCH_SALES_DATES'
export const FETCH_PARAMS = 'FETCH_PARAMS'
export const CHANGE_MONTH_HORIZON_SELECTION = 'CHANGE_MONTH_HORIZON_SELECTION'

export const EXECUTE_QUERY = 'EXECUTE_QUERY'

export const GET_APPLIED_CHANGES = 'GET_APPLIED_CHANGES'
export const GET_FAVOURITES_LIST = 'GET_FAVOURITES_LIST'
export const FAVOURITES_ARE_LOADED = 'FAVOURITES_ARE_LOADED'

export const GET_FAVOURITES = 'GET_FAVOURITES'
export const SAVE_FAVOURITES = 'SAVE_FAVOURITES'
export const UPDATE_FAVOURITES_GROUP = 'UPDATE_FAVOURITES_GROUP'
export const DELETE_FAVOURITES_GROUP = 'DELETE_FAVOURITES_GROUP'

export const GET_FAVOURITES_PENDING = 'GET_FAVOURITES_PENDING'
export const SAVE_FAVOURITES_PENDING = 'SAVE_FAVOURITES_PENDING'
export const UPDATE_FAVOURITES_GROUP_PENDING = 'UPDATE_FAVOURITES_GROUP_PENDING'
export const DELETE_FAVOURITES_GROUP_PENDING = 'DELETE_FAVOURITES_GROUP_PENDING'

export const GET_FAVOURITES_FULFILLED = 'GET_FAVOURITES_FULFILLED'
export const SAVE_FAVOURITES_FULFILLED = 'SAVE_FAVOURITES_FULFILLED'
export const UPDATE_FAVOURITES_GROUP_FULFILLED = 'UPDATE_FAVOURITES_GROUP_FULFILLED'
export const DELETE_FAVOURITES_GROUP_FULFILLED = 'DELETE_FAVOURITES_GROUP_FULFILLED'

export const GET_FAVOURITES_REJECTED = 'GET_FAVOURITES_REJECTED'
export const SAVE_FAVOURITES_REJECTED = 'SAVE_FAVOURITES_REJECTED'
export const UPDATE_FAVOURITES_GROUP_REJECTED = 'UPDATE_FAVOURITES_GROUP_REJECTED'
export const DELETE_FAVOURITES_GROUP_REJECTED = 'DELETE_FAVOURITES_GROUP_REJECTED'

export const APPLY_FAVOURITES_GROUP = 'APPLY_FAVOURITES_GROUP'
export const APPLIED_FAVOURITES_GROUP = 'APPLIED_FAVOURITES_GROUP'

export const SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES'

export const LOAD_GALLERY = 'LOAD_GALLERY'
export const LOAD_GALLERY_FULFILLED = 'LOAD_GALLERY_FULFILLED'

export const ATENCION_MSJ = 'ATENCION_MSJ'
export const SIN_DATOS_MSJ = 'SIN_DATOS_MSJ'
export const LIMPIAR_ERROR_MSJ = 'LIMPIAR_ERROR_MSJ'

export const LOAD_VALID_TM_USERS = 'LOAD_VALID_TM_USERS'
export const LOAD_VALID_TM_USERS_FULFILLED = 'LOAD_VALID_TM_USERS_FULFILLED'

export const ITEM_LIST_CAMPAIGNS = "ITEM_LIST_CAMPAIGNS"
export const ITEM_LIST_CAMPAIGNS_FULFILLED = "ITEM_LIST_CAMPAIGNS_FULFILLED"

export const ITEM_LIST_TASKS = "ITEM_LIST_TASKS"
export const ITEM_LIST_TASKS_FULFILLED = "ITEM_LIST_TASKS_FULFILLED"

export const ITEM_LIST_QUESTIONS = "ITEM_LIST_QUESTIONS"
export const ITEM_LIST_QUESTIONS_FULFILLED = "ITEM_LIST_QUESTIONS_FULFILLED"

export const GENERATE_PDF_GALLERY = "GENERATE_PDF_GALLERY"
export const GENERATE_PDF_GALLERY_FULFILLED = "GENERATE_PDF_GALLERY_FULFILLED"

export const CURRENTPAGINATIONPHOTOS = "CURRENTPAGINATIONPHOTOS"
export const CURRENTPAGINATIONPHOTOS_FULFILLED = "CURRENTPAGINATIONPHOTOS_FULFILLED"