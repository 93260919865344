import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'

import * as comp from './loadableComponents'
import packageJson from '../package.json'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import 'ag-grid/dist/styles/ag-grid.css'
import 'ag-grid/dist/styles/ag-theme-balham.css'

import { authLogin } from './actions/auth_login'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import CacheBuster from './components/cacheBuster';

library.add(faUndo, faTrashAlt, faCheckCircle, faTimesCircle)

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: packageJson.version || 'ERROR'
    }

    let loginToken, pathname
    if (REDIRECT_URL) {
      try {
        if (process.env.REACT_APP_TOKEN_OVERRIDE) {
          loginToken = process.env.REACT_APP_TOKEN_OVERRIDE
        } else {
          loginToken = this.props.location.hash.split('=')[1].split('&')[0]
        }
        pathname = this.props.location.pathname
      } catch (ex) {
        console.error("Can't find loginToken on pathname:", ex)
        window.location = REDIRECT_URL
      }
      this.props.authLogin(loginToken, pathname).then(this.handleResponse, this.handleError)
    } else {
      console.error("Can't find enviroment variables!")
    }
  }

  handleResponse(response) {
    if (!response.value.data.success) {
      window.location = REDIRECT_URL
    }
  }

  handleError(err) {
    console.error('Error de Login', err)
    window.location = REDIRECT_URL
  }

  renderEmoji(char, label) {
    return (
      <span role='img' aria-label={label}>
        {char}
      </span>
    )
  }

  componentDidMount() {
    console.log('Version: ', this.state.version)
  }

  render() {
    return this.props.isAuthenticated ? (
      <div className='App'>
        <main>
          <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
              if (loading) return null;
              if (!loading && !isLatestVersion) {
                return (
                  <div className="dr-page">
                    <div className="alert alert-warning" align="center" role="alert" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); refreshCacheAndReload(); }}>
                      Estás usando una versión antigua! Haz click <a href={'/'} className="alert-link">aquí</a> para actualizar a la última versión!
                    </div>
                  </div>
                )
              }
              return (
                <div>
                  {/*console.log("Bundle version - ", global.appVersion)*/}
                </div>
              );
            }}
          </CacheBuster>
          <Switch>
            <Route path='/'>
              <div className='container'>
                <div>
                  { /* Descargadores clásicos */}
                  <Route exact path='/taskmanager-catalog' component={comp.TaskmanagerCatalog} />
                  <Route exact path='/taskmanager-tasks' component={comp.TaskmanagerTasks} />
                  <Route exact path='/sales-detail-daily' component={comp.DailySalesDetail} />
                  <Route exact path='/sales-detail-weekly' component={comp.WeeklySalesDetail} />
                  <Route exact path='/sales-detail-monthly' component={comp.MonthlySalesDetail} />
                  <Route exact path='/downloader-daily' component={comp.DownloaderDaily} />
                  <Route exact path='/downloader-weekly' component={comp.DownloaderWeekly} />
                  <Route exact path='/downloader-monthly' component={comp.DownloaderMonthly} />
                  <Route exact path='/inventory-doh' component={comp.InventoryDOH} />
                  <Route exact path='/inventory-detail' component={comp.InventoryDetail} />
                  <Route exact path='/instock-detail' component={comp.InstockDetail} />
                  <Route exact path='/catalog-detail' component={comp.CatalogDetail} />
                  <Route exact path='/no-sales-detail' component={comp.NoSalesDetail} />
                  <Route exact path='/lost-sales-detail' component={comp.LostSalesDetail} />
                  <Route exact path='/sales-detail-daily-stock' component={comp.DailySalesWithStockDetail} />
                  <Route exact path='/sales-detail-weekly-stock' component={comp.WeeklySalesWithStockDetail} />
                  <Route exact path='/sales-detail-monthly-stock' component={comp.MonthlySalesWithStockDetail} />
                  { /* Descargadores con favoritos */}
                  <Route exact path='/budget-monthly-product' component={comp.BudgetMonthlyProduct} />
                  <Route exact path='/purchase_orders' component={comp.PurchaseOrders} />
                  <Route exact path='/returns' component={comp.ReturnsDetail} />
                  <Route exact path='/dev/sales-detail-monthly' component={comp.MonthlySalesDetail} />
                  <Route exact path='/dev/sales-detail-weekly' component={comp.WeeklySalesDetail} />
                  <Route exact path='/dev/sales-detail-daily' component={comp.DailySalesDetail} />
                  <Route exact path='/dev/inventory-detail' component={comp.InventoryDetail} />
                  <Route exact path='/inventory-multi-detail' component={comp.InventoryMultiDetail} />
                  <Route exact path='/dev/no-sales-detail' component={comp.NoSalesDetail} />
                  <Route exact path='/dev/lost-sales-detail' component={comp.LostSalesDetail} />
                  <Route exact path='/dev/sales-detail-daily-stock' component={comp.DailySalesWithStockDetail} />
                  <Route exact path='/dev/sales-detail-weekly-stock' component={comp.WeeklySalesWithStockDetail} />
                  <Route exact path='/dev/sales-detail-monthly-stock' component={comp.MonthlySalesWithStockDetail} />
                  <Route exact path='/dev/inventory-doh' component={comp.InventoryDOH} />
                  { /* Descargadores:Fechas como columnas */}
                  <Route exact path='/dev/downloader-daily' component={comp.DownloaderDaily} />
                  <Route exact path='/dev/downloader-weekly' component={comp.DownloaderWeekly} />
                  <Route exact path='/dev/downloader-monthly' component={comp.DownloaderMonthly} />
                  <Route exact path='/downloader-management-weekly' component={comp.DownloaderManagementWeekly} />
                  {/*Descargador Taskmanager*/}
                  <Route exact path='/dev/taskmanager-catalog' component={comp.TaskmanagerCatalog} />
                  <Route exact path='/dev/taskmanager-tasks' component={comp.TaskmanagerTasks} />
                  {/*Descargador Otros*/}
                  <Route exact path='/dev/catalog-detail' component={comp.CatalogDetail} />
                  <Route exact path='/dev/instock-detail' component={comp.InstockDetail} />
                  {/*NUEVOS*/}
                  <Route exact path='/de-indicators' component={comp.OperationalIndicators} />
                  <Route exact path='/de-smart-osa' component={comp.DownloaderSmartOSA} />
                  <Route exact path='/taskmanager-center-tasks' component={comp.TaskmanagerCenterTasks} />
                  <Route exact path='/de-instock-month' component={comp.DEInstockMonth} />
                  <Route exact path='/tm-summary-downloader' component={comp.TMSummaryDownloader} />
                  <Route exact path='/tm-tasks-day-summary-store' component={comp.TMDaySummaryStoreDownloader} />
                  <Route exact path='/downloader-maestra' component={comp.downloaderMaestra} />
                  <Route exact path='/downloader-locales' component={comp.downloaderLocales} />

                  {/* IMGs */}
                  <Route exact path='/taskmanager-img-display' component={comp.TaskmanagerImgDisplay} />
                  <Route exact path='/taskmanager-catalog-display' component={comp.TaskmanagerCatalogDisplay} />
                  <Route exact path='/taskmanager-center-tasks-display' component={comp.TaskmanagerCenterTasksDisplay} />
                  <Route exact path='/oos-x-so' component={comp.oosXSelloutDownloader} />
                  <Route exact path='/inventory-daily' component={comp.InventoryDaily} />

                  {/*  Task 2*/}
                  <Route exact path='/downloader-notes' component={comp.DownloaderTm2Notes} />
                  <Route exact path='/downloader-campaign-realized-task-center' component={comp.DownloaderTm2CampaignRealizedTaskCenter} />
                  <Route exact path='/downloader-actions' component={comp.DownloaderTm2Actions} />
                  <Route exact path='/downloader-product-tasks' component={comp.DownloaderProductTasksTm2} />
                  <Route exact path='/prinsel-daily2' component={comp.PrinselDownloaderDaily2} />
                  {/* IMGs Task 2 */}

                  <Route exact path='/taskmanager2-img-display' component={comp.Taskmanager2ImgDisplay} />
                  <Route exact path='/taskmanager2-center-tasks-display' component={comp.Taskmanager2CenterTasksDisplay} />

                  {/* SMU */}
                  <Route exact path='/sales-smu' component={comp.SalesSMU} />
                  <Route exact path='/stock-smu' component={comp.StockSMU} />
                  <Route exact path='/catalog-smu' component={comp.CatalogSMU} />

                </div>
              </div>
            </Route>
          </Switch>
        </main>
        <br />
      </div>
    ) : (
      <div className='container centered'>
        <ReactLoading type='spinningBubbles' color='#444' delay={0} />
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.userProfile.isAuthenticated
  }
}

const mapDispatchToProps = {
  authLogin
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
// version testing
