import initialState from './initial_state'
import * as types from '../actions/action_types'
import { Accordion } from 'react-bootstrap'

export default function (state = initialState.reducerEmailHierarchies, action) {

    switch (action.type) {
        case types.LOAD_VALID_TM_USERS_FULFILLED:
            return {
                itemListEmailHierarchies: action.payload ? action.payload.data : [{}],
                isEmailHierarchiesLoaded: true
            }

        default:
            return state
    }
}
